import './faq.css';
import { useState } from 'react';

function Question({ question }) {
    const [expanded, Expand] = useState(false);

    // Function to process the answer text
    const formatAnswer = (answer) => {
        // Replace all instances of "/n" with a space and split by actual newlines
        const formattedAnswer = answer.replace(/\/n/g, ' '); // Remove all instances of "/n"
        return formattedAnswer.split('\n').map((line, index) => {
            const trimmedLine = line.trim();
            // Only return non-empty lines with a <br> after them
            return trimmedLine ? (
                <span key={index}>
                    {trimmedLine}
                    <br />
                </span>
            ) : null; // Return null for empty lines
        });
    };

    return (
        <div className='faq-question'>
            <div className='faq-question-header'>
                <h2 className='faq-question-title'>{question.question}</h2>
                <div className="faq-question-button" onClick={() => Expand(!expanded)}>
                    {expanded ? (
                        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 7L6 2L1 7" stroke="black" strokeWidth="2" />
                        </svg>
                    ) : (
                        <svg className="button-icon-notexpanded" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L6 6L11 1" stroke="black" strokeWidth="2" />
                        </svg>
                    )}
                </div>
            </div>
            <div className={`faq-question-desc ${expanded ? "faq-question-desc-expanded" : ""}`}>
                <p>
                    {formatAnswer(question.answer)}
                </p>
            </div>
        </div>
    );
}

export function Faq({ faq }) {
    return (
        <div className="faq">
            <h1>Najczęściej zadawane pytania</h1>
            {faq.map((question, index) => (
                <Question key={index} question={question} />
            ))}
        </div>
    );
}
